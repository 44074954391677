import React, { useMemo } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useTranslation /* , Trans */ } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Avatar from '@material-ui/core/Avatar';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AnnouncementTooltip from '@/components/Layouts/AnnouncementTooltip';
import TermsAndConditions from '@/components/Layouts/TermsAndConditions';
import { ReactComponent as LogoutIcon } from '@/assets/images/icons/logout.svg';
import { ReactComponent as SettingsIcon } from '@/assets/images/icons/settings.svg';
import { ReactComponent as SupportIcon } from '@/assets/images/icons/support.svg';
import { ReactComponent as TotalsIcon } from '@/assets/images/icons/totals.svg';
import { ReactComponent as MerchantAccountIcon } from '@/assets/images/icons/merchantAccount.svg';
import { ReactComponent as UsersIcon } from '@/assets/images/icons/users.svg';
import { ReactComponent as TransactionsIcon } from '@/assets/images/icons/transactions.svg';
import { ReactComponent as TodaysTransactionsIcon } from '@/assets/images/icons/todaysTransactions.svg';
import { ReactComponent as QrcodeIcon } from '@/assets/images/icons/qrcode.svg';

import useAuth from '@/hooks/useAuth';
import ListItemLink from '@/components/ListItemLink';
import paths from '@/router/paths';
import { isAccessible } from '@/router/router-middleware';
import * as permissions from '@/router/permissions';
import usePaymentProfiles from '@/hooks/usePaymentProfiles';
import { PQ_COUNTRY, PROFILES_ALLOWED_TO_DOWNLOAD_QR } from '@/constants/common';

export const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: drawerWidth,
    // overflow: 'unset',
    [theme.breakpoints.up('lg')]: {
      border: 'none',
      boxShadow: theme.shadows[3],
    },
  },
  swipeableDrawerPaper: {
    width: drawerWidth,
    // overflow: 'unset',
    transform: 'translateX(-650px) !important',
    [theme.breakpoints.up('lg')]: {
      border: 'none',
      boxShadow: theme.shadows[3],
    },
  },
  isDrawerOpen: {
    transform: 'none !important',
    transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms !important',
  },
  drawer: {
    [theme.breakpoints.up('lg')]: {
      width: drawerWidth,
      flexShrink: 0,
      zIndex: 3,
    },
  },
  sidebarWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  avatar: {
    width: 80,
    height: 80,
    margin: theme.spacing(4, 'auto', 2),
  },
  name: {
    color: theme.palette.text.primary,
    marginBlock: theme.spacing(0, 4),
    textAlign: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    ...theme.mixins.noWrap,
  },
  list: {
    borderTop: `1px solid ${theme.palette.common.border}`,
    borderBottom: `1px solid ${theme.palette.common.border}`,
    padding: 0,
  },
  listItem: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    '&:hover, &:active, &:focus': {
      backgroundColor: theme.palette.divider,
    },
    '& + &': {
      borderTop: `1px solid ${theme.palette.common.border}`,
    },
  },
  listItemActive: {
    position: 'relative',
    backgroundColor: theme.palette.divider,
    '&:before': {
      content: "''",
      height: '100%',
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      width: 4,
      backgroundColor: '#d33f71',
    },
  },
  listText: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: '24px',
  },
}));

function Sidebar({ mobileOpen, handleDrawerToggle, closeDrawer }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { paymentProfiles } = usePaymentProfiles();
  const { pathname } = useLocation();
  const { user = {} } = useAuth();

  const canDownloadQR = useMemo(
    () =>
      isAccessible({
        oneOf: permissions.QR_CODE_DOWNLOAD,
      }) === 'pass' &&
      [PQ_COUNTRY.BEL, PQ_COUNTRY.LUX].includes(user.pqCountry) &&
      paymentProfiles &&
      paymentProfiles.some(({ productName }) =>
        PROFILES_ALLOWED_TO_DOWNLOAD_QR.includes(productName),
      ),
    [user.pqCountry, paymentProfiles],
  );

  /**
   * An AnnouncementTooltip is part of the UI's effort to announce new features to the user effectively.
   * It will be displayed of next to the Sidebar.
   * To enable announcements for the menu, the menu item objects should contain these parameters:
   *
   * - `showAnnouncementTooltip`: A boolean flag that controls the visibility of the announcement tooltip.
   *     Setting this to `true` enables the tooltip display.
   * - `announcementKey`: This serves as a unique identifier for the announcement stored in local storage.
   *    It tracks whether the user has previously seen and closed the message, preventing repeated displays
   *    of the same announcement to enhance user experience. An example value could be 'newFeatureName'.
   * - `announcementMessage`: This property holds the announcement message and can be a JSX element.
   *
   * Example:
   * showAnnouncementTooltip: true,
   * announcementKey: 'userAnnouncement',
   * announcementMessage: (
   *   <Trans i18nKey="message.announcement" components={{ bold: <b />, break: <br /> }} />
   * ),
   */
  const menuItems = useMemo(
    () =>
      [
        {
          Icon: TodaysTransactionsIcon,
          label: 'menu.latestTransactions',
          url: paths.newTransactions,
          hide:
            isAccessible({
              oneOf: permissions.NEW_TRANSACTIONS_VIEW,
            }) !== 'pass',
        },
        {
          Icon: TransactionsIcon,
          label: 'menu.allTransactions',
          url: paths.allTransactions,
          hide:
            isAccessible({
              oneOf: permissions.ALL_TRANSACTIONS_VIEW,
            }) !== 'pass',
        },
        {
          Icon: TotalsIcon,
          label: 'menu.totals',
          url: paths.totals,
          hide:
            isAccessible({
              oneOf: permissions.TOTALS_VIEW,
            }) !== 'pass',
        },
        {
          Icon: MerchantAccountIcon,
          label: 'menu.merchantaccount',
          url: paths.merchantAccount,
          hide:
            isAccessible({
              oneOf: permissions.MERCHANT_ACCOUNT_VIEW,
            }) !== 'pass',
        },
        {
          Icon: UsersIcon,
          label: 'menu.users',
          url: paths.users,
          hide:
            isAccessible({
              oneOf: permissions.USERS_VIEW,
            }) !== 'pass',
        },
        {
          Icon: SettingsIcon,
          label: 'menu.settings',
          url: paths.settings,
          hide:
            isAccessible({
              oneOf: permissions.SETTINGS_MODIFY,
            }) !== 'pass',
        },
        {
          Icon: QrcodeIcon,
          label: 'menu.downloadQR',
          url: paths.downloadQR,
          hide: !canDownloadQR,
        },
        {
          Icon: SupportIcon,
          label: 'menu.support',
          url: paths.support,
          hide:
            isAccessible({
              oneOf: permissions.SUPPORT_VIEW,
            }) !== 'pass',
        },
        {
          Icon: LogoutIcon,
          label: 'menu.logout',
          url: paths.logout,
        },
      ].filter(({ hide }) => !hide),
    [canDownloadQR],
  );

  const drawer = (
    <div className={classes.sidebarWrapper}>
      <Avatar
        className={classes.avatar}
        variant="square"
        alt={user.name || ''}
        src={user.logoUrl || ''}
      />
      <h3 className={classes.name}>{user.name || ''}</h3>
      <List className={classes.list}>
        {menuItems.map(
          ({ Icon, label, url, showAnnouncementTooltip, announcementKey, announcementMessage }) => (
            <ListItemLink
              disableGutters
              key={label}
              className={clsx(classes.listItem, pathname === url && classes.listItemActive)}
              to={url}
              onClick={closeDrawer}
            >
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.listText }} primary={t(label)} />
              <AnnouncementTooltip
                showAnnouncementTooltip={showAnnouncementTooltip}
                announcementKey={announcementKey}
              >
                {announcementMessage}
              </AnnouncementTooltip>
            </ListItemLink>
          ),
        )}
      </List>
      <TermsAndConditions />
    </div>
  );

  return (
    <nav className={classes.drawer} data-testid="nav">
      <Hidden mdUp implementation="css">
        <SwipeableDrawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          onOpen={handleDrawerToggle}
          classes={{
            paper: clsx(classes.swipeableDrawerPaper, mobileOpen && classes.isDrawerOpen),
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </SwipeableDrawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
}

Sidebar.propTypes = {
  mobileOpen: PropTypes.bool.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  closeDrawer: PropTypes.func.isRequired,
};

export default Sidebar;
