export const CLIENT_TIMEOUT = 'CLIENT_TIMEOUT';
export const CLIENT_ERROR = 'CLIENT_ERROR';
export const UNAUTHORIZED = 401;
export const ACCESS_DENIED = 403;
export const ACCESS_LOCKED = 423;
export const SERVER_ERROR = 'SERVER_ERROR';
export const SESSION_EXPIRED = 'SESSION_EXPIRED';
export const DUPLICATE_EMAIL = 'R125';
export const DEACTIVATED_USER_LOGIN = 'R956';
export const MIGRATED_MERCHANT_LOGIN = 32;
