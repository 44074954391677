import { Theme } from '@material-ui/core';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';

const palette = (theme: Theme): PaletteOptions => ({
  background: {
    default: theme.palette.common.white,
    header: '#F44176',
    item: '#fbf8f5',
    tableHead: '#ebe8e5',
    tableHeadActive: '#aea497',
    active: '#00CB75',
    pending: '#FF6600',
    disabled: '#FF0000',
    step: '#9B9B9B',
    total100: '#00cb75',
    total200: '#04ba6d',
    total300: '#0b985c',
    total400: '#156643',
  },
  common: {
    border: '#EADCC9',
  },
  primary: {
    main: '#00cb75',
  },
  secondary: {
    main: '#ff4785',
  },
  text: {
    primary: '#726d65',
    secondary: '#aea497',
    caption: '#979797',
    black: '#000',
    warning: '#FF9513',
  },
  divider: '#F4EDE4',
});

export default palette;
