import React, { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { IPaymentProfile } from '@/types/paymentProfile';

const useStyles = makeStyles((theme) => ({
  option: {
    padding: theme.spacing(1, 2),
  },
  emptyOption: {
    border: 'none',
    height: 40,
  },
  allOption: {
    border: 'none',
    backgroundColor: theme.palette.divider,
    bottom: 0,
    position: 'absolute',
    left: 0,
    right: 0,
    textAlign: 'center',
    padding: theme.spacing(2, 0),
  },
  profileId: {
    display: 'flex',
    '& strong': {
      display: 'inline-block',
      width: '45px',
      paddingLeft: theme.spacing(0.5),
      direction: 'rtl',
      textAlign: 'left',
      ...theme.mixins.noWrap,
    },
  },
}));

type Props = {
  option: IPaymentProfile;
};

function PaymentProfileAutocompleteOption({ option }: Props): ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();

  if (option.paymentProfileId === 'all') {
    return (
      <div className={classes.allOption}>
        <Typography data-testid="all">{t('pp.allPaymentProfiles')}</Typography>
      </div>
    );
  }

  if (option.paymentProfileId === 'emptyOption') {
    return <div className={classes.emptyOption} />;
  }
  return (
    <div className={classes.option}>
      <Typography>
        <strong>{option.shopName}</strong>
      </Typography>
      <Typography variant="caption" component="p">
        {option.productDescription}
      </Typography>
      <Typography variant="caption" component="p" className={classes.profileId}>
        <Trans
          i18nKey="pp.profileId"
          values={{ profileId: option.paymentProfileId }}
          components={{ bold: <strong /> }}
        />
      </Typography>
    </div>
  );
}

export default PaymentProfileAutocompleteOption;
